import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";
import ContactFormOffer from "../../components/contactFormOffer";
const OfferPage = () => {
	const lang = "en";

	return (
		<Layout
			seoTitle="Our services"
			lang={lang}
			translationPL="/oferta/"
			translationDE="/de/angebot/"
		>
			<SmallBanner title="Our services" lang={lang} />
			<section className="offer-products">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
							<h2 className="color--main mb-100">Products</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-6 col-lg-3 offer-products__product-holder">
							<Link
								className="offer-products__item"
								to="/en/our-services/"
							>
								<img
									className="item__image"
									src={require("../../assets/img/palete.png")}
									alt="Paletten"
								/>
								<p className="bold small">Euro-pallets</p>
								<p className="item__title big">(EPAL)</p>
							</Link>
						</div>
						<div className="col-12 col-md-6 col-lg-3 offer-products__product-holder">
							<Link
								className="offer-products__item"
								to="/en/our-services/"
							>
								<img
									className="item__image"
									src={require("../../assets/img/palete2.png")}
									alt="Paletten"
								/>
								<p className="bold small">CHEMICAL pallets</p>
								<p className="item__title big">(CP)</p>
							</Link>
						</div>
						<div className="col-12 col-md-6 col-lg-3 offer-products__product-holder">
							<Link
								className="offer-products__item"
								to="/en/our-services/"
							>
								<img
									className="item__image"
									src={require("../../assets/img/palete3.png")}
									alt="Paletten"
								/>
								<p className="bold small">SPECIAL</p>
								<p className="item__title big">pallets</p>
							</Link>
						</div>
						<div className="col-12 col-md-6 col-lg-3 offer-products__product-holder">
							<Link
								className="offer-products__item"
								to="/en/our-services/"
							>
								<img
									className="item__image"
									src={require("../../assets/img/palete4.png")}
									alt="Paletten"
								/>
								<p className="bold small">DÜSSELDORF</p>
								<p className="item__title">pallets</p>
							</Link>
						</div>
						<div className="col-12 col-md-6 col-lg-3 offer-products__product-holder">
							<Link
								className="offer-products__item"
								to="/en/our-services/pallet-4-0/"
							>
								<img
									className="item__image"
									src={require("../../assets/img/palete5.png")}
									alt="Paletten"
								/>
								<p className="bold small">Pallets</p>
								<p className="item__title">Pallet 4.0</p>
							</Link>
						</div>
					</div>
				</div>
			</section>
			<section className="offer-services">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
							<h2 className="color--main mb-40">Our services</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon1.svg")}
									alt="Heat treatment (HT) of pallets"
								/>
								<p className="bold item__title">
									Heat treatment (HT) of pallets
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										This enables you to export palletised
										goods worldwide.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon2.svg")}
									alt="Drying of pallets"
								/>
								<p className="bold item__title">
									Drying of pallets
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										This process helps to prevent the growth
										of mould and fungi in the wood.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon3.svg")}
									alt="Branding of pallets"
								/>
								<p className="bold item__title">
									Branding of pallets
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Get guaranteed durability of the
										markings and tailor them to your
										requirements or those of your customer.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon4.svg")}
									alt="Dyeing of pallets"
								/>
								<p className="bold item__title">
									Dyeing of pallets
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										You can distinguish and identify your
										pallet among all pools of pallets by
										dyeing their parts.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon5.svg")}
									alt="Using metal parts in the pallet design"
								/>
								<p className="bold item__title">
									Using metal parts in the pallet design
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Increase the durability of the pallet or
										adapt it for the installation of a box
										or other structure.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon6.svg")}
									alt="Designing pallets according to your own needs"
								/>
								<p className="bold item__title">
									Designing pallets according to your own
									needs
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Customise a pallet to your production
										line, type of goods, and transport
										conditions.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon7.svg")}
									alt="Warehousing and maintaining stock levels"
								/>
								<p className="bold item__title">
									Warehousing and maintaining stock levels
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Get uninterrupted access to your pallets
										without having to devote your own
										storage space to them.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon8.svg")}
									alt="Customised delivery plans, including just-in-time deliveries"
								/>
								<p className="bold item__title">
									Customised delivery plans, including
									just-in-time deliveries
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Tailor the number of deliveries to your
										current needs and optimise your storage
										space.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon9.svg")}
									alt="Strapping"
								/>
								<p className="bold item__title">Strapping</p>
								<div className="item__hover-content">
									<p className="item__text">
										By strapping pallet stacks, we make sure
										that the pallets reach you in perfect
										condition.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon10.svg")}
									alt="Printing on pallets"
								/>
								<p className="bold item__title">
									Printing on pallets
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										We can tailor the markings on a pallet
										to your needs by printing changeable
										symbols, such as a batch number or
										production date.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon11.svg")}
									alt="Staining wood the prevent blue stain fungi"
								/>
								<p className="bold item__title">
									Staining wood the prevent blue stain fungi
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Your pallets can be additionally
										protected against mould during the
										critical periods for wood (late spring
										and summer).
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../../assets/img/offer-icon12.svg")}
									alt="Smart pallets"
								/>
								<p className="bold item__title">
									Smart pallets
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										You can integrate pallets into your
										company’s digital environment by using
										RFID tags.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="offer-quality">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
							<h2 className="color--main mb-40">Quality</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
							<p className="mb-100">
								PalettenWerk has a 6-stage quality control
								system, which guarantees the repeatability,
								durability, and long life of the pallets. The
								system includes control of the delivery of
								pallet components and control of the production
								process and finished products. All control
								processes are integrated into a quality
								management system.
							</p>
						</div>
						<div className="col-12 offer-quality__btn-holder">
							<Link
								className="btn"
								to="/en/our-services/quality/"
								data-hover="Learn more"
							>
								Learn more
								<span className="btn-arrows"></span>
							</Link>
						</div>
					</div>
				</div>
			</section>
			<section className="offer-delivery">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
							<h2 className="color--main mb-40">Delivery</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
							<p className="mb-100">
								PalettenWerk guarantees fast and timely pallet
								deliveries throughout Europe. Orders for
								standard pallets in Poland are completed within
								24 hours, and deliveries to other countries are
								made within 3 working days of placing the order.
							</p>
						</div>
						<div className="col-12 offer-delivery__btn-holder">
							<Link
								className="btn"
								to="/en/our-services/delivery/"
								data-hover="Learn more"
							>
								Learn more
								<span className="btn-arrows"></span>
							</Link>
						</div>
					</div>
				</div>
			</section>
			<div className="col-lg-10 contact_formular">
				<h3>Contact form</h3>
				<ContactFormOffer lang={lang} />
			</div>
		</Layout>
	);
};

export default OfferPage;
